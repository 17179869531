import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./NavBar.module.css";

const logo =
  "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Unikko-logo-sinfondo.png?alt=media&token=e3a178dc-31ec-4fd3-bb49-c14653845dbc";
const menu =
  "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/icons8-menu-rounded.svg?alt=media&token=15c222d2-b2a4-4ad3-8370-f6571f1dc339";
const close =
  "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/closing.svg?alt=media&token=65ea5e4b-cf8b-4971-885c-8cef7bf71232";

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [toggle, setToggle] = useState(false);

  window.onscroll = (e) => {
    window.requestAnimationFrame(function () {
      if (window.scrollY > 100) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    });
  };

  const hideMenu = () => setShowMenu(false);
  const unhideMenu = () => setShowMenu(true);

  return (
    <section
      className={styles.navbarTop}
      style={
        toggle
          ? {
              transition: "all .3s",
              background: "white",
              margin: 0,
              width: "100%",
              padding: "0 5%",
              color: "#181818",
              borderBottom: "1px solid #F1F2F6",
            }
          : { transition: "all .3s" }
      }
    >
      <Link to="/">
        <img src={logo} alt="fixter logo" />
      </Link>
      <div className={styles.tabs}>
        <Link to="/nosotros">
          <h3>Nosotros</h3>
        </Link>
        <Link to="/propiedades">
          <h3>Propiedades</h3>
        </Link>
        <Link to="/fund">
          <h3>Fund</h3>
        </Link>
        <Link to="/contacto">
          <h3>Contacto</h3>
        </Link>
        <a
          href="https://share.hsforms.com/1c6qRVvLeQPmPzbiwRLAmIg5m7rp"
          rel="noopener noreferrer"
          target="_blank"
        >
          <button className={styles.btn}>Reservar</button>
        </a>
      </div>
      <div className={styles.mobile}>
        <img
          className={styles.menuIcon}
          onClick={unhideMenu}
          src={menu}
          alt="menu icon"
        />
        <div
          className={classNames({
            [styles["menu-drop"]]: !showMenu,
            [styles["menuDropActive"]]: showMenu,
          })}
        >
          <img
            className={styles.closeIcon}
            onClick={hideMenu}
            src={close}
            alt="menu icon"
          />
          <Link to="/">
            <img className={styles.logoIcon} src={logo} alt="fixter logo" />
          </Link>
          <Link to="/nosotros">
            <h3>Nosotros</h3>
          </Link>
          <Link to="/propiedades">
            <h3>Propiedades</h3>
          </Link>
          <Link to="/fund">
            <h3>Fund</h3>
          </Link>
          <Link to="/contacto">
            <h3>Contacto</h3>
          </Link>
          <a
            href="https://share.hsforms.com/1c6qRVvLeQPmPzbiwRLAmIg5m7rp"
            rel="noopener noreferrer"
            target="_blank"
          >
            <button className={styles.btn}>Reservar</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default NavBar;
