import styles from "./Footer.module.css"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
const facebook = "https://firebasestorage.googleapis.com/v0/b/camp-92fe8.appspot.com/o/Images%2FFacebook.svg?alt=media&token=dd512334-1cea-4ffc-9129-b76731585d7c"
const twitter = "https://firebasestorage.googleapis.com/v0/b/camp-92fe8.appspot.com/o/Images%2FTwitter.svg?alt=media&token=d2ac63c0-4106-4f59-830d-86cea0ce6183"
const instagram = "https://firebasestorage.googleapis.com/v0/b/camp-92fe8.appspot.com/o/Images%2FInstagram.svg?alt=media&token=5f8432d4-efd3-4667-993e-435cbdeb29ad"

export default function Footer() {
    return (
        <section className={styles.footer}>
            <div className={styles.footerBox}>
                <div className={styles.cta}>
                    <p className={styles.invite}>¿Quieres saber más acerca de Unikko? Escríbenos a</p>
                    <a href="mailto:info@unikko.es" aria-label="Mail" rel="noopener noreferrer">
                        <p className={styles.invite}> <span style={{ color: "#E8B140" }}>info@unikko.es</span></p>
                    </a>
                </div>
                <div className={styles.links}>
                    <p className={styles.subtitle}>Acerca de</p>
                    <Link to="/nosotros">
                        <p>Nosotros</p>
                    </Link>
                    <Link to="/contacto">
                        <p>Contacto</p>
                    </Link>
                    <Link to={"/login"}>
                        <p>
                       Login
                        </p>
                    </Link>

                </div>
            </div>
            <hr />
            <div className={styles.disclaimers}>

                <div className={styles.mediaBox}>
                    <a href='/' rel="noopener noreferrer" target='_blank'>
                        <img className={styles.media} src={facebook} alt="facebook" />
                    </a>
                    <a href='/' rel="noopener noreferrer" target='_blank'>
                        <img className={styles.media} src={twitter} alt="twitter" />
                    </a>
                    <a href='/' rel="noopener noreferrer" target='_blank'>
                        <img className={styles.media} src={instagram} alt="instagram" />
                    </a>
                </div>
                <p>© 2022 Unikko</p>
            </div>

        </section>
    )
}