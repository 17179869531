import * as React from 'react'
import styles from "./List.module.css"
import NavBar from '../navbar/NavBar';
import { PropertyList } from '../admin/dashboard/Dashboard.tsx';
import useSmartScroll from '../../hooks/useSmartScroll';
import usePropertiesAndCities from '../../hooks/usePropertiesAndCities.tsx'

const List = () => {
    const { apartments, glamp, cities, onCityFilter } = usePropertiesAndCities()

    useSmartScroll()

    return <section >
        <NavBar />
        <section className={styles.list}>
            <div>
                <h2 className={styles.header}>Propiedades</h2>
                <p className={styles.intro}> Alojamientos diferentes, para gente diferente. Creamos el plan perfecto adaptado a las preferencias de nuestros clientes.
                    Una experiencia personalizada donde abarcamos desde zonas urbanas hasta destinos de playa y parajes naturales.
                </p>
                <p className={styles.intro}>
                    Con clientes cada vez más flexibles a la hora de hospedarse, nos sumamos a los conceptos más revolucionarios en el mundo del hospedaje.
                </p>
                <p className={styles.intro}>Con Unikko encontrará la alternativa a su medida:
                </p>
                <div className={styles.tagsBox}>
                    <div className={styles.tag}>#Larga estancia</div>
                    <div className={styles.tag}>#Alquileres Corporativos</div>
                    <div className={styles.tag}>#Alquileres Vacacionales</div>
                    <div className={styles.tag}>#Co-Living</div>
                    <div className={styles.tag}>#Glamping</div>
                    <div className={styles.tag}>#Turismo rural</div>
                    <div className={styles.tag}>#Turismo sostenible</div>
                </div>
            </div>
            <div >
                <PropertyList
                    isAdmin={false}
                    apartments={apartments}
                    glamp={glamp}
                    cities={cities} onFilter={onCityFilter}
                />
            </div>
        </section>
    </section>
}
export default List