import * as React from 'react'
import classNames from 'classnames'

import styles from "./TabBar.module.css"

const TabBar = ({ onClick, active }) => {

    return (
        <section className={styles.wrap}>
            <div className={styles.container}>

                <button
                    onClick={() => onClick(0)}
                    className={classNames(styles.toggleButton, {
                        [styles.active]: active === 0
                    })}>Viviendas</button>
                <button
                    onClick={() => onClick(1)}
                    className={classNames(styles.toggleButton, {
                        [styles.active]: active === 1
                    })}>Glampings</button>
                <div
                    style={{
                        "--i": active
                    }}
                    className={classNames(styles.selector, {
                        [styles.toggleButton]: true,
                        [styles.selectorButton]: true,
                    })
                    }>
                </div>

            </div>
        </section>
    )
}

export default TabBar