import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function useSmartScroll() {
    const history = useHistory()
    useEffect(() => {
        history.listen((_, action) => { // scroll when coming from navlink only
            if (action === 'PUSH') {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        })
    }, [])
    return null;
}