import { useState, useEffect, useMemo } from 'react'
import styles from "./PropertyModal.module.css"
import * as yup from 'yup'
import { Formik, Form, useField } from 'formik'
import { Link } from "react-router-dom";
import { updateProperty } from './utils.ts';
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert2'
import { deleteProperty, uploadMainPic, uploadPics, getUID } from './utils.ts';

const readAsDataURL = (file) => new Promise((res, rej) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        if (reader.error) return rej(reader.error);
        res(reader.result);
    }
})

const close = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/closing.svg?alt=media&token=65ea5e4b-cf8b-4971-885c-8cef7bf71232"

const schema = yup.object().shape({
    name: yup.string().max(30).required('Máximo 30 caracteres'),
    description: yup.string().max(1000).required('Máximo 1000 caracteres'),
    city: yup.string().required('Escribe una ciudad'),
    district: yup.string(),
    surface: yup.string(),
    rooms: yup.string(),
    toilets: yup.string(),
    rentType: yup.string().required('Llena este campo'),
    price: yup.string().required('Llena este campo'),
    reservationLink: yup.string().required('Ingresa la url'),
    tag: yup.string().required('Selecciona uno')
    // cover:yup.string().required('Selecciona una imagen'),
    // pics:yup.string().required('Selecciona las imagenes'),
});

const CustomField = (props) => {
    const [field, meta] = useField(props)
    return (
        <div className={styles.inputContainer}>
            <label>{props.label}</label>
            {props.type === 'textarea' ?
                <textarea
                    {...field}
                    {...props}
                    className={styles.input} type={props.type || 'text'} placeholder={props.placeholder || 'Llena este campo'}
                    rows={12}
                    style={{ height: '100px', }}
                />
                : <input
                    {...field}
                    {...props}
                    className={styles.input} type={props.type || 'text'} placeholder={props.placeholder || 'Llena este campo'}
                    accept={props.type === 'file' ? 'image/png image/jpeg' : undefined}
                />
            }
            {meta.touched && meta.error ? <span style={{ color: 'red' }}>{meta.error}</span> : null}
        </div>
    )
}
const CustomMultipleField = (props) => {
    const [field, meta] = useField(props)
    return (
        <div className={styles.fieldContainer}>
            <label>{props.label}</label>
            {props.type === 'textarea' ?
                <textarea
                    {...field}
                    {...props}
                    className={styles.input} type={props.type || 'text'} placeholder={props.placeholder || 'Llena este campo'}
                    rows={12}
                    style={{ height: '100px', }}
                />
                : <input
                    {...field}
                    {...props}
                    className={styles.input} type={props.type || 'text'} placeholder={props.placeholder || 'Llena este campo'}
                    accept={props.type === 'file' ? 'image/png image/jpeg' : undefined}
                />
            }
            {meta.touched && meta.error ? <span style={{ color: 'red' }}>{meta.error}</span> : null}
        </div>
    )
}
const SwitchSelect = (props) => {
    const [field, meta] = useField(props) // send props always! 
    return (
        <div className={styles.selectInput}>
            <label>
                Disponibilidad*
            </label>
            <select
                {...field}
                {...props}
                className={styles.inputContainer} // TODO: update this class please is awful 
                name="available"
                defaultValue=''
            >
                <option value='' >{props.label}</option>
                <option value="Disponible">Disponible</option>
                <option value="No disponible">No disponible</option>
            </select>
            <div style={{ marginTop: '-24px', paddingBottom: '48px' }}>
                {meta.touched && meta.error ? <span style={{ color: 'red' }}>{meta.error}</span> : null}
            </div>
        </div>
    )
}
const TypeSelect = (props) => {
    const [field, meta] = useField(props) // send props always! 
    return (
        <div className={styles.selectInput}>
            <label>
                Periodicidad de alquiler
            </label>
            <select
                {...field}
                {...props}
                className={styles.inputContainer} // TODO: update this class please is awful 
                name="rentType"
                defaultValue=''
            >
                <option value='' >{props.label}</option>
                <option value="noche">Por noche</option>
                <option value="semana">Por semana</option>
            </select>
            <div style={{ marginTop: '-24px' }}>
                {meta.touched && meta.error ? <span style={{ color: 'red' }}>{meta.error}</span> : null}
            </div>
        </div>
    )
}

const CustomSelect = (props) => {
    const [field, meta] = useField(props) // send props always! 
    return (
        <div className={styles.selectInput}>
            <label>
                Tipo de propiedad*
            </label>
            <select
                {...field}
                {...props}
                className={styles.inputContainer} // TODO: update this class please is awful 
                name="tag"
                defaultValue=''
            >
                <option value='' >{props.label}</option>
                <option value="Apartamento">Viviendas</option>
                <option value="Glamping">Glamping</option>
            </select>
            <div style={{ marginTop: '-24px' }}>
                {meta.touched && meta.error ? <span style={{ color: 'red' }}>{meta.error}</span> : null}
            </div>
        </div>
    )
}

export default function PropertyModal({ initialValues }) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState({
        main: null,
        pics: null,
        mainPreview: '',
        previews: [],
    })

    useEffect(() => {
        const escHandler = ({ key }) => {
            if (key === 'Escape') {
                history.goBack();
            }
        }
        window.addEventListener('keydown', escHandler)

        return () => {
            window.removeEventListener('keydown', escHandler)
        }
    }, [history]);

    const hanldeSubmit = async (form) => {
        setLoading(true)
        // upload files and get url,
        // update form with file links
        // send to firebase wait for answer notify
        let ref
        let mainPicURL
        let picsURLS
        if (!form.id) {
            form.id = getUID()
        }
        if (files.main) {
            mainPicURL = await uploadMainPic(files.main, form.id) // this can be undefined
            form.cover = mainPicURL
        }
        if (files.pics) {
            picsURLS = await uploadPics(files.pics, form.id);
            form.pics = picsURLS
        }
        await updateProperty(form)
        ref = { type: 'update' }
        if (ref.type === 'document') {
            // go back
            history.goBack();
            swal.fire('Éxito', 'La propiedad se ha guardado con éxito', 'success')
        } else if (ref.type === 'update') {
            history.goBack();
            swal.fire('Éxito', 'La propiedad se ha actualizado', 'success')
        } else {
            // swal.fire('¡Oh no!', 'Ocurrió un error', 'error')
        }


    }

    const handleMainPic = async ({ target: { files: [file] } }) => {
        const url = await readAsDataURL(file)
        setFiles(fs => ({ ...fs, main: file, mainPreview: url }))
    }

    const handlePics = async ({ target: { files } }) => {
        const arr = [...new Set(files)]
        const previews = await Promise.all(arr.map(file => readAsDataURL(file)))
        setFiles(fs => ({
            ...fs,
            pics: [...arr],
            previews
        }))
    }

    const pics = useMemo(() => {
        return files.previews.length ? files.previews.map(url => <img width="50px" height="50px" key={url} src={url} alt="preview" />) :
            initialValues?.pics ? initialValues.pics.map(url => <img width="50px" height="50px" key={url} src={url} alt="preview" />) : 'No hay fotos'
    }, [initialValues, files])

    return (
        <section className={styles.modal}>
            <div className={styles.card}>
                <Link to="/dashboard">
                    <img className={styles.float} src={close} alt="closing icon" />
                </Link>
                <h2>Completa la información</h2>
                <Formik
                    initialValues={initialValues || {
                        available: '',
                        name: '',
                        description: '',
                        city: '',
                        district: '',
                        surface: '',
                        rooms: '',
                        toilets: '',
                        rentType: '',
                        price: '',
                        monthPrice: '',
                        months: '',
                        reservationLink: '',
                        // cover:'',
                        // pics:'',
                        tag: '',
                    }}
                    validationSchema={schema}
                    onSubmit={hanldeSubmit}
                >
                    {(props) => (
                        <Form className={styles.form}>
                            <CustomSelect name="tag" label="Selecciona el tipo de propiedad" />
                            <SwitchSelect name="available" label="Selecciona la disponibilidad" />
                            <CustomField name="name" label="Nombre*" placeholder="Máximo 30 caracteres" />
                            <CustomField name="city" label="Ciudad*" type="text" placeholder="Ciudad" />
                            <CustomField name="district" label="Distrito" type="text" placeholder="Distrito" />
                            <CustomField name="surface" label="Superficie" type="text" placeholder="m2 o ha" />
                            <CustomField name="rooms" label="Número de habitaciones" type="number" placeholder="1" />
                            <CustomField name="toilets" label="Número de baños" type="number" placeholder="2" />
                            <CustomField name="description" label="Descripción*" type="textarea" placeholder="Máximo 1000 caracteres" />
                            <CustomField name="reservationLink" label="Link de reserva*" type="text" placeholder="Ingresa la url" />
                            <TypeSelect name="rentType" label="Selecciona la periodicidad" />
                            <CustomField name="price" label="Precio*" type="text" placeholder="€00.00 USD" />
                            <CustomField name="monthPrice" label="Precio por mes*" type="text" placeholder="€00.00 USD" />
                            <CustomField name="months" label="Mínimo de meses para estancia larga*" type="text" placeholder="3" />
                            <div className={styles.boxField}>
                                <CustomMultipleField name="fotos" onChange={handleMainPic} label="Fotografía principal*" type="file" />
                                {files.mainPreview ? <img width="60px" height="50px" src={files.mainPreview} alt="preview" /> :
                                    initialValues?.cover ? <img alt="property photos" width="60px" height="50px" src={initialValues.cover} alt="preview" /> : null
                                }
                            </div>

                            <div className={styles.boxField}>
                                <CustomMultipleField name="portada" onChange={handlePics} label="Fotografías extra" type="file" multiple />
                                <div style={{ display: 'flex' }} className={styles.picsBox}>
                                    {pics}
                                </div>
                            </div>
                            <aside className={styles.actions}>
                                {initialValues?.id && <div className={styles.btnBox}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            swal.fire({
                                                text: '¿Realmente quieres eliminar esta propiedad?',
                                                title: 'Esta acción no tiene retorno.',
                                                showCancelButton: true,
                                                cancelButtonText: 'Cancelar',
                                                confirmButtonText: 'Si, eliminar',
                                                confirmButtonColor: '#d33',
                                                cancelButtonColor: '#9779f6',
                                            }).then(async (result) => {
                                                if (result.isConfirmed) { // aqui eliminamos
                                                    setLoading(true)
                                                    await deleteProperty(initialValues.id);
                                                    swal.fire('¡Propiedad Eliminada!', '', 'success')
                                                    history.goBack();
                                                } else if (result.isDenied) {
                                                    //   swal.fire('Changes are not saved', '', 'info')
                                                }
                                            })
                                        }}
                                        disabled={loading}
                                        className={styles.btnDelete}>
                                        Eliminar
                                    </button>
                                </div>}
                                <div className={styles.btnBox}>
                                    <button
                                        disabled={loading
                                            // || !props.dirty
                                        }
                                        type="submit" className={styles.btn}>
                                        {loading ? <Spinner /> : 'Guardar'}
                                    </button>
                                </div>
                            </aside>
                        </Form>

                    )}
                </Formik>

            </div>
        </section>
    )
}

export const Spinner = () => (
    <img alt="spinner" className={styles.spinner} src="https://cdn-icons-png.flaticon.com/512/248/248961.png" />
)