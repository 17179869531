import styles from "./About.module.css"
import Fade from 'react-reveal/Fade';
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import NavBar from "../navbar/NavBar";

const image2 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Toril%2FPool%202.jpg?alt=media&token=b58b2547-9ece-4c9b-a7d6-fb8e03c8270b"
const image3 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Toril%2FDSCF3091.jpg?alt=media&token=002c8ddf-fb31-4ca2-abb1-ba3799c9429d"
const image4 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Toril%2FBubble%208.jpg.jpg?alt=media&token=0da30d98-3583-4b13-8bc3-14dda75993d0"
const image7 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/hortaleza%2F230048994.jpg?alt=media&token=04363c74-2bca-484e-b57c-6e112bf460cc"
const image8 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/hortaleza%2F230152368.jpg?alt=media&token=8d85f068-e8d5-45c9-90cf-5b8c78cb12d2"
const image13 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/alberto%2FWhatsApp%20Image%202021-09-17%20at%2019.40.26%20(4).jpeg?alt=media&token=a571538c-8ad4-4bd0-a519-f7b393394e5f"
const image14 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/alberto%2FWhatsApp%20Image%202021-09-17%20at%2019.40.26%20(9).jpeg?alt=media&token=7aa99b51-9c43-4e66-9eea-83d0b3c2e872"
const image15 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Toril%2FDSCF3033.jpg?alt=media&token=c0169e3b-bf30-47de-81cf-7759ff77eb9e"
const image5 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/service1.png?alt=media&token=f601b64e-a758-4f8f-8d4c-1d3b1cd573dc"
const image6 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/service2.png?alt=media&token=a1ef5652-7af9-4f19-baba-119af8622076"

export default function About() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <NavBar />
            <section className={styles.about}>
                <Fade bottom>
                    <h2 className={styles.header}>Sobre Nosotros</h2>
                    <p className={styles.text}>Nos esforzamos día a día en brindar a nuestros clientes estancias de lujo inolvidable. Ofrecemos un servicio personalizado de principio a fin, cuidando al máximo todos los detalles.
                    </p>
                    <p className={styles.text}>Unikko Properties se centra en la rama inmobiliaria, donde cubrimos desde inversión inmobiliaria, hasta la gestión de vivienda de alquiler vacacional, larga estancia y gestión hotelera.
                    </p>
                    <p className={styles.text}>Nuestro principal objetivo es garantizar que nuestros clientes queden satisfechos con nuestros servicios.</p>
                </Fade>
                <Fade bottom>
                    <div className={styles.collage}>
                        <div>
                            <div className={styles.small} style={{ backgroundImage: `url("${image3}")` }}></div>
                            <div className={styles.small} style={{ backgroundImage: `url("${image15}")` }}></div>
                        </div>
                        <div className={styles.tall} style={{ backgroundImage: `url("${image2}")` }}></div>
                        <div>
                            <div className={styles.small} style={{ backgroundImage: `url("${image8}")` }}></div>
                            <div className={styles.small} style={{ backgroundImage: `url("${image7}")` }}></div>
                        </div>
                        <div className={styles.tall} style={{ backgroundImage: `url("${image4}")` }}></div>
                        <div>
                            <div className={styles.small} style={{ backgroundImage: `url("${image13}")` }}></div>
                            <div className={styles.small} style={{ backgroundImage: `url("${image14}")` }}></div>
                        </div>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className={styles.info}>
                        <h2 className={styles.header}>Los mejores Glampings y Viviendas</h2>
                        <p className={styles.text}>Entre nuestras propiedades, destaca la ubicación y la calidad del servicio que se ofrece.
                            Actualmente, nuestro catálogo de localizaciones se centra en la Península Ibérica, con mayor presencia en la zona centro, Madrid y Toledo.
                        </p>
                        <LazyLoadImage effect="blur" src={image6} alt="glampings example" />
                        <LazyLoadImage effect="blur" src={image5} alt="apartament example" />
                    </div>
                </Fade>
            </section >
        </section>
    )
}