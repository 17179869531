import styles from "./Login.module.css"
import { useHistory } from "react-router-dom"
import { useState, useEffect } from 'react'
import { login } from "./dashboard/utils.ts"
import { Link } from "react-router-dom"

const logo = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Unikko-logo-sinfondo.png?alt=media&token=e3a178dc-31ec-4fd3-bb49-c14653845dbc"

export default function Login() {
    const history = useHistory()
    const [form, setForm] = useState({ email: '', password: '' })
    const onChange = ({ target: { value, name } }) => setForm({ ...form, [name]: value })


    useEffect(() => {
        const uid = localStorage.getItem('uid')
        if (uid) { history.push('/dashboard') }
    }, [])

    const handleLogin = (e) => {
        e.preventDefault()
        login(form)
            .then(user => {
                if (user) {
                    history.push('/dashboard')
                }
            })
    }
    return (
        <section className={styles.login}>
            <div className={styles.loginCard}>
                <Link to="/">
                    <img src={logo} alt="logo unikko" />
                </Link>
                <h2>¡Bienvenido!</h2>
                <p>Completa los campos para iniciar sesión.</p>
                <form onSubmit={handleLogin} className={styles.form}>
                    <div>
                        <label>Email</label>
                        <input onChange={onChange} name="email" placeholder="ejemplo@unikko.com" />
                    </div>
                    <div>
                        <label>Contraseña</label>
                        <input type="password" onChange={onChange} name="password" placeholder="********" />
                    </div>
                    <button
                        disabled={form.email === '' || form.password === ''}
                        type="submit"
                        className={styles.btn}>Iniciar sesión</button>

                </form>
            </div>
        </section>
    )
}