import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropertyModal, { Spinner } from "./PropertyModal";
import { getOneProperty } from "./utils.ts";

export default function Edit() {
  const params = useParams();
  const [property, setProperty] = useState();

  useEffect(() => {
    const getItem = async () => {
      const data = await getOneProperty(params.id);
      setProperty(data);
    };
    getItem();
  }, [params.id]);

  return property ?  <PropertyModal initialValues={property} /> : <div style={{height:'95vh',display:'flex',alignItems:'center',justifyContent:'center'}} ><Spinner /></div>; // tODO: skeleton
}
