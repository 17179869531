import styles from "./Home.module.css"
import Fade from 'react-reveal/Fade';

const star = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/icons8-star-filled.svg?alt=media&token=2cc55ac1-8007-46a9-a238-a65d30dbd9bb"

export default function Steps() {
    return (
        <section className={styles.clients}>
            <Fade bottom>
                <h2 className={styles.title}>Lo que dicen nuestros clientes sobre nosotros</h2>
                <div className={styles.boxComments}>
                    <div className={styles.comment}>
                        {/* <div className={styles.photo}></div> */}
                        <div className={styles.content}>
                            <span >
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                            </span>
                            <p>Alojamiento perfecto para grandes grupos, muy bien equipado con pinpong, billar, futbolín, skate, pista fútbol, basket, tenis y frontón, así como gran piscina y una pequeña charca para pescar. La atención de 10, 24 h disponibles, el desayuno espectacular, como en un hotel. El personal es muy atento y amable. Recomendable
                            </p>
                            <h3>Luis</h3>
                        </div>
                    </div>
                    <div className={styles.comment}>
                        {/* <div className={styles.photo}></div> */}
                        <div className={styles.content}>
                            <span >
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                            </span>
                            <p>Excelente experiencia para desconectar. La atención ha sido excelente, todos muy atentos y amables y el lugar es un encanto.
                                Estuvimos esta vez en la carpa África y sin duda repetiremos para probar la experiencia de las burbujas. Muchas gracias a todo el equipo de El Toril por darnos un finde de 1</p>
                            <h3>Veronica</h3>
                        </div>
                    </div>
                    <div className={styles.comment}>
                        {/* <div className={styles.photo}></div> */}
                        <div className={styles.content}>
                            <span >
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                                <img src={star} alt="yellow star" />
                            </span>
                            <p>Experiencia increible, super recomendable, estar en el jacuzzi en medio del campo es una sensación única, al igual que dormir bajo la luz de la luna y las estrellas. La burbuja está bien equipada y super cómoda. El desayuno es increíble y muy abundante. Repetiremos seguro. El personal muy atento y pendientes de todo.</p>
                            <h3>Lidia</h3>
                        </div>
                    </div>
                </div>
            </Fade>
        </section>
    )
}