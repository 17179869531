import Footer from './pages/footer/Footer.jsx';
import Routes from './Routes.jsx';

function App() {
  return (
    <div>
      <Routes />
      <Footer />
    </div>
  );
}

export default App;