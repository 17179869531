import * as React from 'react'
import { getProperties } from '../pages/admin/dashboard/utils.ts'

export default function usePropertiesAndCities() {
    const [apartments, setApartments] = React.useState([])
    const [glamp, setGlamp] = React.useState([])
    const [cities, setCities] = React.useState([])
    const [all, setAll] = React.useState([])

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        // cities
        const makeCities = (properties) => {
            const list = [...new Set(properties?.map(p => p.city))]
            if (list.length) {

                setCities(list)
            }
        }

        // get docs
        const getDocs = async () => {
            const properties = await getProperties();
            setAll(properties)
            makeCities(properties)
            // lets separate them
            setApart(properties)
        }

        getDocs()

    }, []);

    const setApart = (properties: any[], city?: string) => {
        let a = properties.filter(p => p.tag === 'Apartamento')
        if (city) a = a.filter(p => p.city === city)
        let g = properties.filter(p => p.tag === 'Glamping')
        if (city) g = g.filter(p => p.city === city)
        setApartments(a)
        setGlamp(g)
    }

    const onCityFilter = (city) => {
        if (city === 'none') {
            setApart(all)
            return;
        }
        setApart(all, city)
    }

    return {
        glamp, cities, apartments, onCityFilter
    }
}