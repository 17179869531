import styles from "./Home.module.css"
import Fade from 'react-reveal/Fade';

const step1 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/step1.png?alt=media&token=74c05a49-9944-448e-a327-f080547b5d48"
const step2 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/money.png?alt=media&token=1df7d0fb-76df-47f0-a670-3fd1a98cdd86"
const step3 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/property.png?alt=media&token=c65724b2-b0b2-44fa-b470-ce44a176b8b1"

export default function Steps() {
    return (
        <section className={styles.steper}>
            <Fade bottom>
                <h2 className={styles.title}>The Unikko Fund</h2>
                <div className={styles.steperBox}>
                    <div className={styles.cardS}>
                        <div className={styles.surface} style={{ background: "#E67871" }}>
                            <img src={step1} alt="invest" />
                        </div>
                        <h3 className={styles.cardTitle}>Invierte</h3>
                        <p className={styles.cardText}>En una rama dinámica, en pleno auge  y alternativa  del sector Inmobiliario </p>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.circleY} style={{ background: "#E67871" }} />
                        <hr />
                        <div className={styles.circleM} style={{ background: "#9779F6" }} />
                    </div>
                    <div className={styles.cardS}>
                        <div className={styles.surface} style={{ background: "#9779F6" }}>
                            <img src={step3} alt="share" />
                        </div>
                        <h3 className={styles.cardTitle}>Comparte</h3>
                        <p className={styles.cardText}>El riesgo a través del crowdfunding y una comunidad </p>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.circleY} style={{ background: "#9779F6" }} />
                        <hr />
                        <div className={styles.circleM} style={{ background: "#50B6D0" }} />
                    </div>
                    <div className={styles.cardS}>
                        <div className={styles.surface} style={{ background: "#50B6D0" }}>
                            <img src={step2} alt="invest" />
                        </div>
                        <h3 className={styles.cardTitle}>Recibe</h3>
                        <p className={styles.cardText}>Beneficios únicos como inversor en nuestras propiedades, además de un retorno de tu inversión.</p>
                    </div>
                </div>
            </Fade>
        </section>
    )
}