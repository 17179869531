import { useEffect } from 'react';
import styles from "./Contact.module.css"
import Fade from 'react-reveal/Fade';
import NavBar from '../navbar/NavBar';

const mapa = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/map.png?alt=media&token=37805054-1ef6-426b-86a6-46ff97198031"

export default function Contact() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (

        <section >
            <meta
                name="description"
                content="Creamos una experiencia humana y diferente de la investigación de mercado en línea, reclutando y perfilando usuarios con base en los requerimientos de tu estudio para asegurar resultados de calidad."
            />
            <meta
                name="keywords"
                content="encuestas, encuesta, estudios de mercado, perfilamiento de usuarios, diseño de encuestas, análisis de datos, panel, panel de usuarios"
            />
            <title>Unikko | Contacto </title>
            <NavBar />
            <section className={styles.contact}>

                <div className={styles.contactInfo}>
                    <Fade bottom>
                        <h2>Contáctanos</h2>
                        <div>
                            <h3>Visitanos en</h3>

                            <p>Paseo Eduardo Dato 12 , 28010, Madrid  </p>

                        </div>
                        <div>
                            <h3>Escríbenos a</h3>
                            <a href="mailto:info@unikko.es" aria-label="Mail" noopener noreferrer>
                                <p>info@unikko.es</p>
                            </a>
                            <a href="tel:+31403033781" aria-label="tel" noopener noreferrer>
                                <p>+34 680 951 768</p>
                            </a>
                        </div>
                        <div>
                            <h3>Agenda una cita</h3>
                            <a href="https://meetings.hubspot.com/unikko" target='blank' noopener noreferrer>
                                <button className={styles.btnSecondary}>Agendar</button>
                            </a>

                        </div>
                    </Fade>
                </div>
                <Fade bottom>
                    <div className={styles.map} >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d925.2658382382903!2d-3.6920216339184013!3d40.432579778834054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422892a4e11d83%3A0x28560eab19c180f8!2sP.%C2%BA%20de%20Eduardo%20Dato%2C%2012%2C%2028010%20Madrid%2C%20Espa%C3%B1a!5e0!3m2!1ses!2smx!4v1653865400462!5m2!1ses!2smx" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                    </div>
                </Fade>
            </section>


        </section>
    )
}