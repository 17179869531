import { useEffect } from 'react';
import styles from "./Home.module.css"
import Steps from './Steps';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Testimonials from "./Testimonials"
import Partners from "./Partners"
import Fade from 'react-reveal/Fade';
import NavBar from '../navbar/NavBar';
import { Link } from "react-router-dom"

const image = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/DSCF2994%20(1).jpg?alt=media&token=e7c9b1e1-79e3-4a67-a98b-392b9ec72f1a"
const bubble = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/DSCF3091.jpg?alt=media&token=d53ce628-08f6-4b47-9cbd-d7a898682320"
const img1 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/230152368.jpg?alt=media&token=7f489f89-1dd3-4586-875f-1103c627cc2d"
const img2 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/230153256.jpg?alt=media&token=84a5e996-97e0-402c-b387-54321cf35b02"
const img3 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/WhatsApp%20Image%202021-09-17%20at%2019.40.26%20(4).jpeg?alt=media&token=51b20645-6528-4696-bb48-cc0eaca6a978"
const img4 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/DSCF3008.jpg?alt=media&token=56cc7d0a-030d-47b3-8647-0d429db8b6ac"
const img5 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/DSCF3009%20(1).jpg?alt=media&token=643d61b5-e229-4251-a57d-d4bce49b4c4a"
const hotel = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/WhatsApp%20Image%202021-09-17%20at%2019.21.32%20(3).jpeg?alt=media&token=0ac6abfc-e1bf-41db-867f-e06724204e7d"
const mouse = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/general%2Fmpuse.png?alt=media&token=28706166-04f5-4cb6-b8b9-c933ca3baf61"

export default function Home() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (

        <section >
            <meta
                name="description"
                content="Creamos una experiencia humana y diferente de la investigación de mercado en línea, reclutando y perfilando usuarios con base en los requerimientos de tu estudio para asegurar resultados de calidad."
            />
            <meta
                name="keywords"
                content="encuestas, encuesta, estudios de mercado, perfilamiento de usuarios, diseño de encuestas, análisis de datos, panel, panel de usuarios"
            />
            <title>Unikko</title>
            <NavBar />
            <section className={styles.welcome} >
                <div className={styles.cover}>
                    <div>
                        <Fade bottom>
                            <h2>Encuentra el lugar ideal para vivir una experiencia única</h2>
                            <h3>Descubre nuestros alojamientos <strong style={{ fontWeight: "bolder" }}>Glampings & Viviendas</strong> </h3>
                            <Link to="/propiedades">
                                <button className={styles.btn}>Explorar</button>
                            </Link>
                            <br />
                        </Fade>
                        <img className={styles.mouse} src={mouse} alt="white mouse scrolling" />
                    </div>
                </div>
            </section>
            <section className={styles.services}>
                <Fade bottom>
                    <h2 className={styles.title}>Descubre los mejores precios</h2>
                    <div className={styles.cardBox}>
                        <Link to="/propiedades">
                            <article className={styles.card}>
                                <div className={styles.cardImage}>
                                    <img src={img5} />

                                </div>
                                <h3>Glampings</h3>
                            </article>
                        </Link>
                        <Link to="/propiedades">
                            <article className={styles.card}>
                                <div className={styles.cardImage}>
                                    <img src={img1} />
                                </div>
                                <h3>Apartamentos</h3>
                            </article>
                        </Link>
                    </div>
                </Fade>
            </section>
            <section className={styles.about}>
                <Fade bottom>
                    <div className={styles.aboutInfo}>
                        <h2>Sobre Nosotros</h2>
                        <p className={styles.text}>Nos esforzamos día a día en brindar a nuestros clientes estancias de lujo inolvidable. Ofrecemos un servicio personalizado de principio a fin, cuidando al máximo todos los detalles.
                        </p>
                        <br />
                        <Link to="/nosotros">
                            <button className={styles.btn}>Saber más</button>
                        </Link>
                    </div>
                </Fade>
                <div className={styles.aboutImages}>
                    <div style={{ backgroundImage: `url("${bubble}")` }}></div>
                    <div style={{ backgroundImage: `url("${hotel}")` }}></div>
                    <div style={{ backgroundImage: `url("${image}")` }}></div>
                    <div style={{ backgroundImage: `url("${img3}")` }}></div>
                    <div style={{ backgroundImage: `url("${img4}")` }}></div>
                    <div style={{ backgroundImage: `url("${img2}")` }}></div>
                </div>
            </section>
            <Steps />
            <Testimonials />
            {/* <Partners /> */}
        </section >
    )
}