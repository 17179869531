import {
  addDoc,
  collection,
  DocumentReference,
  getFirestore,
  getDocs,
  doc,
  getDoc,
  DocumentData,
  setDoc,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import swal, { SweetAlertResult } from 'sweetalert2';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: 'AIzaSyDPo_sZShcS4o9ewpZr1O_frrO-XraiXi8',
//   authDomain: 'tests-4cafc.firebaseapp.com',
//   databaseURL: 'https://tests-4cafc.firebaseio.com',
//   projectId: 'tests-4cafc',
//   storageBucket: 'tests-4cafc.appspot.com',
//   messagingSenderId: '701538060757',
//   appId: '1:701538060757:web:8eb574c13832b16ae0155c',
// };
const firebaseConfig = {
  apiKey: 'AIzaSyCtQLneUZDCQkVacyQoSe744oMens11s6A',
  authDomain: 'unikko-property.firebaseapp.com',
  databaseURL: 'https://unikko-property.firebaseio.com',
  projectId: 'unikko-property',
  storageBucket: 'unikko-property.appspot.com',
  messagingSenderId: '790331051967',
  appId: '1:790331051967:web:bbf8ecc215e7c27ea4db0a',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();

export type PropertyProps = {
  name: string;
  available: string,
  description: string;
  city: string;
  district: string;
  surface?: string;
  rooms?: number;
  toilets: number;
  rentType: string,
  price: number;
  reservationLink: string;
  cover: string;
  pics: string[];
  id?: string;
};

export const uploadMainPic = async (file, propertyId) => {
  const mainPicRef = ref(storage, propertyId);
  const task = uploadBytesResumable(mainPicRef, file);
  return new Promise((res) => {
    task.on('state_changed', undefined, undefined, () => {
      getDownloadURL(task.snapshot.ref).then((url) => res(url));
    });
  });
};

export const uploadPics = async (files, propertyId) => {
  const tasks = files.map((file, index) => {
    const picRef = ref(storage, `${propertyId}/${index}`);
    return uploadBytesResumable(picRef, file);
  });
  // setting listeners
  const promises = tasks.map((task) => {
    return new Promise((res) => {
      task.on('state_changed', undefined, undefined, () => {
        getDownloadURL(task.snapshot.ref).then((url) => res(url));
      });
    });
  });
  return await Promise.all(promises);
};

export const logout = (): Promise<void> => {
  const auth = getAuth();
  localStorage.removeItem('uid');
  return signOut(auth);
};

export const login = ({ email, password }) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      localStorage.setItem('uid', userCredential.user.uid);
      return userCredential.user;
    })
    .catch((e) => {
      console.log(e.code);
      if (e.code === 'auth/invalid-email' || e.code === 'auth/user-not-found') {
        swal.fire('Ops!', 'Email incorrecto', 'error');
      } else if (e.code === 'auth/wrong-password') {
        swal.fire('Ops!', 'Password incorrecto', 'error');
      } else if (e.code === 'auth/too-many-requests') {
        swal.fire(
          'Debes esperar unos minutos',
          'Tu cuenta se ha blockeado por seguridad, intenta más tarde',
          'error'
        );
      }
    });
};

export const deleteProperty = async (id): Promise<void> => {
  await deleteDoc(doc(db, 'properties', id));
};

export const getUID = (): string => {
  return doc(collection(db, 'properties')).id;
};

export const updateProperty = async (
  form: PropertyProps
): Promise<DocumentData | undefined> => {
  const docRef = doc(db, 'properties', form.id);
  await setDoc(docRef, form, { merge: true }).catch((e) => {
    console.log(e.code);
    return swal.fire('¡Oh no!', 'Vuelve a iniciar sesión', 'error');
  });
  return await getOneProperty(form.id);
};

export const createProperty = async (
  form: PropertyProps
): Promise<DocumentReference | SweetAlertResult<any>> => {
  return await addDoc(collection(db, 'properties'), {
    ...form,
    createdAt: serverTimestamp(),
  }).catch((e) => swal.fire('¡Oh no!', 'Vuelve a iniciar sesión', 'error'));
};

export const getProperties = async (): Promise<PropertyProps[]> => {
  const snap = await getDocs(collection(db, 'properties'));
  let docs = [];
  snap.forEach((doc) => docs.push({ ...doc.data(), id: doc.id }));
  return docs;
};

export const getOneProperty = async (id): Promise<DocumentData | undefined> => {
  const docRef = doc(db, 'properties', id);
  const snap = await getDoc(docRef);
  return { ...snap.data(), id };
};
