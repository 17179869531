import React from 'react';
import styles from './PropertyCard.module.css';
import { Link } from "react-router-dom"

const bed = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/bed.svg?alt=media&token=58cc880b-d441-498e-b024-36acf1887001"
const bath = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/bath.svg?alt=media&token=ef3709f3-faeb-474e-9868-7027f9508cac"
const mt = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/meters.svg?alt=media&token=6f498464-fcbf-42e4-a2a3-6e224e52d8ba"

export const PropertyCard = ({ available, cover, id, name, address, image, meters, rooms, bathrooms, rentType, price, surface, isAdmin = true }) => {
    return (
        //TODO: change for detail page
        <Link to={isAdmin ? `/dashboard/${id}/edit` : `/propiedades/${id}`}>
            <section className={styles.card}>
                {
                    available === 'No disponible' ?
                        <div className={styles.chip}> {available}</div> : null
                }
                <div className={styles.img}
                >

                    <img src={cover || image} alt="property" />
                </div>
                <div className={styles.cardContent}>
                    <h3 className={styles.title}>{name}</h3>
                    <p className={styles.address}>{address}</p>
                    <p ><span className={styles.price}>€ {price} EUR</span> <span className={styles.date} > / {rentType}</span></p>
                    <div className={styles.perks}>
                        <div>
                            <img src={mt} alt="meters" />
                            <span>{surface || 'n/a'}</span>
                        </div>
                        <div>
                            <img src={bed} alt="bed" />
                            <span>{rooms || 'n/a'}</span>
                        </div>
                        <div>
                            <img src={bath} alt="bed" />
                            <span>{bathrooms || 'n/a'}</span>
                        </div>
                    </div>
                </div>

            </section>
        </Link>
    );
};