import styles from "./Fund.module.css"
import Fade from 'react-reveal/Fade';
import { useEffect } from 'react';
import NavBar from "../navbar/NavBar";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const img = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/imgs3.png?alt=media&token=8fcbe4fa-cc17-465c-a8fc-2d51e1f91aa3"
const img2 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/imgss.png?alt=media&token=c540002a-1ca4-4829-80a0-1e6652790bf4"
const gif1 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/growth.gif?alt=media&token=5e406b7a-4ce1-43cb-9292-0bdbd0489e2c"
const gif2 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/wallet.gif?alt=media&token=e0e3bc04-3eb5-482f-b4c2-81ff2c19783a"
const gif3 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Tent%20(1).gif?alt=media&token=3d6f3af9-fbf1-4ef0-b516-6361f7527abe"
const gif4 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Gift.gif?alt=media&token=7cda312b-3adc-4c78-852e-38f42bce4868"
const gif5 = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Care.gif?alt=media&token=95521e33-d147-4845-91be-2192ff249710"

export default function Fund() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <NavBar />
            <section className={styles.fund}>

                <Fade bottom>
                    <h2 className={styles.header}>Coming soon: The Fund</h2>
                    <div className={styles.box}>
                        <div>
                            <h2>¿Qué es “The Fund”?</h2>
                            <p>The Unikko Fund  invierte en terrenos y propiedades que sean enclaves únicos de nuestro planeta, con el objetivo de desarrollar proyectos hoteleros singulares.
                            </p>
                            <p>Se elabora un análisis detallado del lugar y se determina la viabilidad del proyecto  y se suma a la cadena de proyectos en curso. Con esto, se lanza una ronda de inversión  y se acompaña de <strong><i> crowdfunding</i></strong> para financiar la obra civil e implementación del método <strong><i> Glamping , que es la abreviación de los conceptos de Glamour y Camping.</i> </strong> </p>
                            <a href="https://share.hsforms.com/1c6qRVvLeQPmPzbiwRLAmIg5m7rp" target="_blank" rel="noreferrer">
                                <button className={styles.btn} style={{ backgroundColor: '#9779f6' }}>Contactar agente</button>
                            </a>
                        </div>
                        <LazyLoadImage effect="blur" src={img2} alt='glamping' />
                    </div>
                </Fade>
                <div className={styles.boxi}>
                    <LazyLoadImage effect="blur" src={img} alt='real state' />
                    <div>
                        <h2>¿Cómo va a funcionar?</h2>
                        <p>
                            El <strong><i> crowdfunding</i></strong>es una forma de financiación colectiva en la que muchas personas invierten pequeñas cantidades de dinero en un proyecto en particular. En el caso de The Unikko Fund, cuenta con un sistema de crowdfunding  híbrido entre deuda y servicios , donde los inversores siempre contarán con unos derechos singulares en cada una de las propiedades de Unikko , permitiéndoles hospedarse en ellas a unos precios exclusivos no abiertos al público general y al mismo tiempo recibir un retorno de su inversión .
                        </p>
                    </div>

                </div>
                <h2 style={{ textAlign: "center" }}>¿Por qué Unikko?</h2>
                <div className={styles.companiesSteps}>

                    <Fade bottom>
                        <div className={styles.card}>
                            <img className={styles.icon} src={gif1} alt='growth chart' />
                            <h3 className={styles.stepsh}> Potencial de crecimiento</h3>
                            <p>El sector del glamping está en auge, ya que cada vez más personas buscan experiencias únicas y cercanas a la naturaleza.</p>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={styles.card}>
                            <img className={styles.icon} src={gif2} alt='walltet' />
                            <h3 className={styles.stepsh}> Diversificación de la cartera</h3>
                            <p> La inversión en el Unikko Fund  permitirá al inversor diversificar su cartera y reducir el riesgo al tener exposición a un sector diferente . Esto puede ayudar a mitigar el riesgo en caso de que otros mercados sufran una desaceleración o una recesión.</p>
                        </div>
                    </Fade>

                </div>
                <div className={styles.companiesSteps}>
                    <Fade bottom>
                        <div className={styles.card}>
                            <img className={styles.icon} src={gif3} alt='glamping' />
                            <h3 className={styles.stepsh}>Crowdfunding híbrido</h3>
                            <p>
                                El modelo de crowdfunding híbrido entre deudas y servicios es un enfoque innovador para la financiación del proyecto, lo que significa que la inversión se distribuye entre diferentes fuentes de financiamiento. Además, los servicios exclusivos que se ofrecen en las propiedades de glamping pueden proporcionar un valor adicional fuera del económico , que es disfrutar de manera palpable de los beneficios de su inversión .
                            </p>

                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={styles.card}>
                            <img className={styles.icon} src={gif4} alt='gift' />
                            <h3 className={styles.stepsh}> Derechos exclusivos</h3>
                            <p>Los inversores en el fondo de glamping tendrán derechos exclusivos sobre los servicios que se ofrecen en las propiedades de glamping, lo que significa que podrán visitar las diferentes propiedades a unos precios únicos y con un trato especial.
                            </p>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={styles.card}>
                            <img className={styles.icon} src={gif5} alt='enviroment' />
                            <h3 className={styles.stepsh}>Responsabilidad social</h3>
                            <p>Al invertir en el fondo de glamping, el inversor no solo está apoyando un proyecto que puede ser rentable, sino que también está contribuyendo a la protección y conservación de la naturaleza y el medio ambiente, debido al enfoque sostenible de estos alojamientos.
                            </p>
                        </div>
                    </Fade>

                </div>


            </section>
        </section >
    )
}