import styles from "./PropertyDetail.module.css"
import Lightbox from 'react-image-lightbox';
import { useState, useEffect, useMemo } from "react";
import 'react-image-lightbox/style.css';
import Fade from 'react-reveal/Fade';
import NavBar from "../navbar/NavBar";
import { useParams } from "react-router-dom";
import { getOneProperty } from "../admin/dashboard/utils.ts";
import { nanoid } from "nanoid";

const bed = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/bed.svg?alt=media&token=58cc880b-d441-498e-b024-36acf1887001"
const bath = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/bath.svg?alt=media&token=ef3709f3-faeb-474e-9868-7027f9508cac"
const img = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/general%2Fskeleton.png?alt=media&token=dec07ef6-6b47-4efb-9158-f3146e9b34eb"
const mt = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/meters.svg?alt=media&token=6f498464-fcbf-42e4-a2a3-6e224e52d8ba"

const images = [
    img,
    "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/general%2Fskeleton.png?alt=media&token=dec07ef6-6b47-4efb-9158-f3146e9b34eb",
]

export default function PropertyDetail() {
    const [property, setProperty] = useState({})
    const params = useParams()
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        // get property
        const getProperty = async () => {
            const p = await getOneProperty(params.id)
            p.pics = p.cover && p.pics ? [p.cover, ...p.pics] : p.cover ? [p.cover] : null
            setProperty(p)
        }
        getProperty()
    }, [])

    const pics = useMemo(() => property?.pics && property.pics.length ? property.pics : images, [property])

    return (
        <section>
            <NavBar />

            <section className={styles.propertyDetail}>
                <Fade clear>
                    <div className={styles.imagesBox}>
                        <div onClick={() => {
                            setIsOpen(true)
                            setPhotoIndex(0)
                        }
                        }
                            className={styles.mainImage} style={{ backgroundImage: `url("${pics[0]}")` }}></div>
                        <div className={styles.secondaryImages}
                        >
                            {pics.slice(0, 4).map((img, i) => {
                                if (i === 0) { return null } // we don't show cover image
                                return (
                                    <PreviewImage
                                        length={pics.length - 4}
                                        onClick={() => {
                                            setIsOpen(true)
                                            setPhotoIndex(i)
                                        }}
                                        index={i} key={nanoid()} img={img} />
                                )
                            })}
                        </div>
                    </div>
                </Fade>
                <Fade clear>
                    <div className={styles.propertyInfo}>
                        <div className={styles.data}>
                            <h2 className={styles.name}>
                                {property.name}
                            </h2>
                            <p className={styles.address}>
                                {property.city}
                            </p>
                            <p className={styles.address}>
                                {property.description}
                            </p>
                            <div className={styles.perks}>
                                <div>
                                    <img src={mt} alt="meters" />
                                    <span>{property.surface || 'n/a'}</span>
                                </div>
                                <div>
                                    <img src={bed} alt="bed" />
                                    <span>{property.rooms || 'n/a'}</span>
                                </div>
                                <div>
                                    <img src={bath} alt="bed" />
                                    <span>{property.toilets || 'n/a'}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.actionsBox}>
                            <div className={styles.priceBox}>
                                <p>Desde</p>
                                <h4><span className={styles.price}>€ {property.price} EUR</span> <span className={styles.date}>/ {property.zrentType}</span></h4>
                                <a href={property.reservationLink} target="_blank">
                                    <button className={styles.btn}>Reservar</button>
                                </a>
                            </div>
                            <div className={styles.priceBox}>
                                <p>Precio estancia larga</p>
                                <p style={{ color: '#8A909B', fontSize: '14px', margin: '4px 0px 24px 0px' }}>Estancia mínima de {property.months} meses</p>
                                <h4><span className={styles.price}>€ {property.monthPrice} EUR</span> <span className={styles.date}>/ mes</span></h4>
                                <a href="https://share.hsforms.com/1c6qRVvLeQPmPzbiwRLAmIg5m7rp" target="_blank">
                                    <button className={styles.btn} style={{ backgroundColor: '#9779f6' }}>Contactar agente</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </Fade>
                {isOpen && (
                    <Lightbox
                        mainSrc={pics[photoIndex]}
                        nextSrc={pics[(photoIndex + 1) % pics.length]}
                        prevSrc={pics[(photoIndex + pics.length - 1) % pics.length]}
                        onMovePrevRequest={() =>
                            setPhotoIndex(
                                (photoIndex + pics.length - 1) % pics.length
                            )
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex(
                                (photoIndex + 1) % pics.length
                            )
                        }
                        onCloseRequest={
                            () => setIsOpen(false)
                        }
                    />
                )}
            </section>
        </section>
    )
}

const PreviewImage = ({ length, onClick, index, img }) => {
    console.log("indeX:", index)
    if (index === 3) {
        return (
            <div

                onClick={onClick}
                key={img}
                style={{ overflow: 'hidden', position: 'relative', backgroundImage: `url("${img}")` }}>
                <p
                    className={styles.microScale}
                    style={{

                        margin: 0,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        display: 'grid',
                        placeItems: 'center',
                        fontSize: '1.4rem',
                        color: 'white'
                    }}>
                    {length} fotos más...
                </p>
            </div>
        )
    }
    return (
        <div
            onClick={onClick}
            key={img} style={{ backgroundImage: `url("${img}")` }}></div>
    )
} 