import * as React from 'react'
import classNames from 'classnames'
import TabBar from '../../list/TabBar'
import styles from "./Dashboard.module.css"
import { PropertyCard } from '../../list/PropertyCard'
import { Link, useHistory } from "react-router-dom"
import { useState } from 'react'
import { logout } from './utils.ts'
import usePropertiesAndCities from '../../../hooks/usePropertiesAndCities.tsx'


const logo = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/Unikko-logo-sinfondo.png?alt=media&token=e3a178dc-31ec-4fd3-bb49-c14653845dbc"
const image = "https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/nodta.png?alt=media&token=50a232cb-a850-4ddc-9403-3a14503e610e"

type PropertyListProps = {
    apartments: any[],
    glamp: any[],
    isAdmin?: boolean,
    cities: any[],
    onFilter: (arg0: string) => void

}

const Dashboard = () => {
    const history = useHistory()
    //TODO: get this logic be part of propertyList
    const { apartments, glamp, cities, onCityFilter } = usePropertiesAndCities()

    return <section>
        <section className={styles.navbarTop}>
            <Link to="/">
                <img src={logo} alt="fixter logo" />
            </Link>
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    logout()
                    history.push('/login')
                }}
                className={styles.tabs}>
                <h3>Cerrar sesión</h3>
            </div>
        </section>
        <section className={styles.dashboard}>
            <div className={styles.header}>
                <h2 >Administra tus apartamentos</h2>
                <Link to="/dashboard/new">
                    <button className={styles.btn}>+ Propiedad</button>
                </Link>
            </div>
            <PropertyList apartments={apartments} glamp={glamp} cities={cities} onFilter={onCityFilter} />
        </section>
    </section>
}
export default Dashboard

export const PropertyList = ({ apartments, glamp, isAdmin, onFilter, cities = [] }: PropertyListProps) => {
    const [active, setActive] = useState(0)
    const handleClick = (index) => {
        setActive(index)
        localStorage.setItem('tabIndex', index)
    }

    React.useEffect(() => {
        const i = localStorage.getItem('tabIndex')
        if (i) {
            setActive(Number(i))
        }
    }, [])

    console.log("cities? ", cities)

    return (
        <div>
            <div className={styles.chip}>
                <TabBar active={active} onClick={handleClick} />
                <div className={styles.selectBox}>
                    <select className={styles.select} onChange={({ target: { value } }) => onFilter(value)} >
                        <option value="none">Todas las ciudades</option>
                        {cities.map(c => (
                            <option value={c}>{c}</option>
                        ))}
                    </select>
                </div>

            </div>
            {/* <div className={styles.select}>
                <select id="ciudad">
                    <option>Ciudad 1</option>
                    <option>Ciudad 2</option>
                    <option>Ciudad 3</option>
                </select>

            </div> */}
            <div className={classNames(styles.info, {
                [styles.visible]: active === 0
            })}>

                <div className={styles.listBox}>
                    {apartments.length ?
                        apartments.map(item => (
                            <PropertyCard
                                isAdmin={isAdmin}
                                {...item}
                                key={item.id}
                                name={item.name}
                                address={`${item.city}, ${item.district}`}
                                price={`${item.price}`}
                                rooms={item.rooms}
                                bathrooms={item.toilets}
                                link={item.reservationLink}
                                image="https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/230152549.jpg?alt=media&token=b51461c7-ac88-4741-8bdb-3efb9d92c8fc"

                            />
                        )) :
                        //TODO: a better empty info feedback
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}><img src={image} style={{ opacity: '.5', width: '180px' }} alt='empty state icon' /> <p>No tenemos apartamentos en esta ciudad. Pero sí glampings. 😊</p></div>
                    }
                </div>
            </div>
            <div className={classNames(styles.info, {
                [styles.visible]: active === 1
            })}>
                <div className={styles.listBox}>
                    {glamp.length ?
                        glamp.map(item => (
                            <PropertyCard
                                isAdmin={isAdmin}
                                {...item}
                                key={item.id}
                                name={item.name}
                                address={`${item.city}, ${item.district}`}
                                price={`$${item.price}`}
                                rooms={item.rooms}
                                bathrooms={item.toilets}
                                link={item.reservationLink}
                                image="https://firebasestorage.googleapis.com/v0/b/unikko-property.appspot.com/o/230152549.jpg?alt=media&token=b51461c7-ac88-4741-8bdb-3efb9d92c8fc"

                            />
                        )) :
                        //TODO: a better empty info feedback
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}><img src={image} style={{ opacity: '.5', width: '180px' }} alt='empty state icon' /> <p>No tenemos glampings en esta ciudad. Pero sí apartamentos. 😊</p></div>
                    }
                </div>
            </div>
        </div>
    )
}