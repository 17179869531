import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './pages/home/Home'
import Contact from './pages/contact/Contact'
import List from "./pages/list/List"
import Fund from './pages/fund/Fund'
import About from './pages/about/About'
import PropertyDetail from './pages/list/PropertyDetail'
import Login from './pages/admin/Login'
import Dashboard from './pages/admin/dashboard/Dashboard.tsx'
import PropertyModal from './pages/admin/dashboard/PropertyModal'
import Edit from './pages/admin/dashboard/Edit.jsx'

const AuthRoute = (props) => {
    const uid = localStorage.getItem('uid')
    if (uid) {
        return <Route {...props} />
    } else {
        return <Redirect to="/login" />
    }
}

export default () => <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/contacto" component={Contact} />
    <Route path="/nosotros" component={About} />
    <Route exact path="/propiedades" component={List} />
    <Route path="/fund" component={Fund} />
    <Route path="/propiedades/:id" component={PropertyDetail} />
    <Route path="/login" component={Login} />
    <AuthRoute exact path="/dashboard" component={Dashboard} />
    <AuthRoute path="/dashboard/new" component={PropertyModal} />
    <AuthRoute path="/dashboard/:id/edit" component={Edit} />
</Switch>